<template>
  <div v-if="approvalData">
    <!-- 승인정보 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">승인정보</span>
        </div>
      </div>
      <!-- 승인일시 -->
      <div class="info-content-wrap">
        <span class="content-category">승인일시</span>
        <span>{{ approvalData.contractDate }}</span>
      </div>
      <!-- 계약번호 -->
      <div class="info-content-wrap">
        <span class="content-category">계약번호</span>
        <span>{{ approvalData.esntlId }}</span>
      </div>
      <!-- 계약번호 -->
      <div class="info-content-wrap">
        <span class="content-category">파트너코드</span>
        <span>{{ approvalData.partnerEsntlId }}</span>
      </div>
      <!-- 유형 -->
      <div class="info-content-wrap">
        <span class="content-category">유형</span>
        <span>{{ getCompStatus(approvalData.adminType) }}</span>
      </div>
    </div>

    <!-- 계약 현황 -->
    <div class="info-container" style="padding: 25px 0">
      <!-- 계약 현황 -->
      <div class="info-content-wrap">
        <span class="content-category">계약 현황</span>
        <span
          :class="`contract-status-${approvalData.contractStatus.toLowerCase()}`"
          >{{ getContractStatus(approvalData.contractStatus) }}</span
        >
        <span class="info-content-date" v-if="approvalData.endDate"
          >{{ approvalData.endDate }} 까지</span
        >
      </div>
    </div>

    <!-- 계약자 기본정보 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약자 기본정보</span>
        </div>
      </div>
      <!-- 관리자 유형 -->
      <div class="info-content-wrap">
        <span class="content-category">관리자 유형</span>
        <span>{{ getCompStatus(approvalData.adminType) }}</span>
      </div>
      <!-- 관리자 이름 -->
      <div class="info-content-wrap">
        <span class="content-category">관리자 이름</span>
        <span>{{ approvalData.name }} {{ approvalData.gender }}</span>
      </div>
      <!-- 휴대폰번호 -->
      <div class="info-content-wrap">
        <span class="content-category">휴대폰번호</span>
        <span>{{ formatPhone(approvalData.phone) }}</span>
      </div>
      <!-- 아이디 -->
      <div class="info-content-wrap">
        <span class="content-category">아이디</span>
        <span>{{ approvalData.email }}</span>
      </div>
      <!-- 거주지역 -->
      <div class="info-content-wrap">
        <span class="content-category">거주지역</span>
        <span>{{ approvalData.addr }} {{ approvalData.addr2 }}</span>
      </div>
      <!-- 활동지역 -->
      <div class="info-content-wrap">
        <span class="content-category">활동지역</span>
        <span>{{ approvalData.busiArea }}</span>
      </div>
      <!-- 분야 -->
      <div class="info-content-wrap">
        <span class="content-category">분야</span>
        <span
          >{{ approvalData.highSpecialty }} >
          {{ approvalData.lowSpecialty }}</span
        >
      </div>
    </div>

    <!-- 계약 사업자 정보 -->
    <div class="info-container" v-if="approvalData.adminType === 'C'">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약 사업자 정보</span>
        </div>
      </div>
      <!-- 상호명 -->
      <div class="info-content-wrap">
        <span class="content-category">상호명</span>
        <span>{{ approvalData.compName }}</span>
      </div>
      <!-- 사업자등록번호 -->
      <div class="info-content-wrap">
        <span class="content-category">사업자등록번호</span>
        <span>{{ approvalData.compRegNumber }}</span>
      </div>
      <!-- 대표자 이름 -->
      <div class="info-content-wrap">
        <span class="content-category">대표자 이름</span>
        <span>{{ approvalData.compCeoName }}</span>
      </div>
      <!-- 사업장 소재지 -->
      <div class="info-content-wrap">
        <span class="content-category">사업장 소재지</span>
        <span>{{ approvalData.compAddr }} {{ approvalData.compAddr2 }}</span>
      </div>
      <!-- 사업자 구분 -->
      <div class="info-content-wrap">
        <span class="content-category">사업자 구분</span>
        <span>{{ approvalData.compClass }}</span>
      </div>
      <!-- 업태 -->
      <div class="info-content-wrap">
        <span class="content-category">업태</span>
        <span>{{ approvalData.compStatus }}</span>
      </div>
      <!-- 업종 -->
      <div class="info-content-wrap">
        <span class="content-category">업종</span>
        <span>{{ approvalData.compType }}</span>
      </div>
    </div>

    <!-- 계약 내용 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약 내용</span>
        </div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          @click="isContentEdit = true"
          v-if="!isContentEdit"
          >수정</button-common
        >
        <div v-if="isContentEdit">
          <button-common
            class="mr-8"
            :size="SIZE_T"
            :clr="CLR_W"
            @click="isContentEdit = false"
            >취소</button-common
          >
          <button-common :size="SIZE_T" :clr="CLR_M" @click="editContract"
            >수정</button-common
          >
        </div>
      </div>
      <!-- 계약명 -->
      <div class="info-content-wrap" style="align-items: flex-start">
        <span class="content-category">계약명</span>
        <span v-if="!isContentEdit">{{ approvalData.title }}</span>
        <input-text v-model="contractTitle" class="w-100 mr-8" v-else />
      </div>
      <!-- 내용 -->
      <div class="info-content-wrap" style="align-items: flex-start">
        <span class="content-category">내용</span>
        <div
          style="height: 300px; overflow: auto"
          v-html="approvalData.content"
          v-if="!isContentEdit"
        />
        <!-- <span v-html="approvalData.content" v-if="!isContentEdit" /> -->
        <editor-common class="mr-8" :content.sync="contract" v-else />
      </div>
    </div>

    <!-- 계약 세부사항 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약 세부사항</span>
        </div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          @click="isSpecialEdit = true"
          v-if="!isSpecialEdit"
          >수정</button-common
        >
        <div v-if="isSpecialEdit">
          <button-common
            class="mr-8"
            :size="SIZE_T"
            :clr="CLR_W"
            @click="isSpecialEdit = false"
            >취소</button-common
          >
          <button-common
            :size="SIZE_T"
            :clr="CLR_M"
            @click="editSpecialContract"
            >수정</button-common
          >
        </div>
      </div>
      <!-- 기간 -->
      <div class="info-content-wrap">
        <span class="content-category">기간</span>
        <span>{{ approvalData.contractPeriod }}개월</span>
      </div>
      <!-- 자동결제 수수료 (VAT포함) -->
      <div class="info-content-wrap">
        <span class="content-category">자동결제 수수료 (VAT포함)</span>
        <span>{{ approvalData.commission }}%</span>
      </div>
      <!-- 해지위약금 수수료 (VAT포함) -->
      <div class="info-content-wrap">
        <span class="content-category">해지위약금 수수료 (VAT포함)</span>
        <span>{{ approvalData.cancelPenalty }}%</span>
      </div>
      <!-- 특약사항 -->
      <div class="info-content-wrap" style="align-items: flex-start">
        <span class="content-category">특약사항</span>
        <span v-html="approvalData.specialContent" v-if="!isSpecialEdit" />
        <editor-common class="mr-8" :content.sync="specialContract" v-else />
      </div>
    </div>

    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          @click="goCompanyContractList"
          >돌아가기</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          style="color: #f1431d"
          @click="requestCancel"
          v-if="approvalData.contractStatus === 'USED'"
          >중도해지</button-common
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CompanyContractDetail",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      approvalData: null,
      isContentEdit: false,
      isSpecialEdit: false,
      contract: "",
      contractTitle: "",
      specialContract: "",
    };
  },

  /*** created ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    if (this.$route.params.id) {
      await this.getContractData();
    }
  },

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {
    isContentEdit() {
      if (this.isContentEdit) {
        this.contract = this.approvalData ? this.approvalData.content : "";
        this.contractTitle = this.approvalData ? this.approvalData.title : "";
      }
    },
    isSpecialEdit() {
      if (this.isSpecialEdit) {
        this.specialContract = this.approvalData
          ? this.approvalData.specialContent
          : "";
      }
    },
  },

  /*** methods ***/
  methods: {
    /** @ 계약 정보 조회 ***/
    async getContractData() {
      const rs = await this.$axios({
        url: "/admin/partner/contract/approval/detail",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.approvalData = rs.resultData;
      } else {
        console.log("계약승인 정보 조회 실패");
      }
    },

    /** @ 계약 내용 수정 ***/
    async editContract() {
      const rs = await this.$axios({
        url: "/admin/partner/contract/contract",
        params: {
          esntlId: this.$route.params.id,
          title: this.contractTitle,
          content: this.contract,
        },
      });
      if (rs.result === "SUC") {
        this.isContentEdit = false;
        this.getContractData();
      } else {
        console.log("계약 내용 수정 실패");
      }
    },

    /** @ 특약 내용 수정 ***/
    async editSpecialContract() {
      const rs = await this.$axios({
        url: "/admin/partner/contract/specialContract",
        params: {
          esntlId: this.$route.params.id,
          specialContract: this.specialContract,
        },
      });
      if (rs.result === "SUC") {
        this.isSpecialEdit = false;
        this.getContractData();
      } else {
        console.log("특약 내용 수정 실패");
      }
    },

    async requestCancel() {
      await this.$openConfirm(
        {
          bodyTx: "파트너 계약을 중도해지 하시겠습니까?",
          btnScndNm: "취소",
          btnPrmyNm: "해지",
          cbPrmy: this.doCancel,
          data: {},
        },
        this
      );
    },

    /** @ 중도 해지 ***/
    async doCancel() {
      const rs = await this.$axios({
        url: "/admin/partner/contract/term",
        params: {
          esntlId: this.$route.params.id,
        },
      });
      if (rs.result === "SUC") {
        this.goCompanyContractList();
      } else {
        console.log("중도 해지 실패");
      }
    },

    getAppType(type) {
      switch (type) {
        case "APPLY":
          return "신규";
        case "CANCEL":
          return "해지";
      }
    },

    getCompStatus(type) {
      switch (type) {
        case "C":
          return "사업자";
        case "P":
          return "개인";
      }
    },

    getApprovalStatus(type) {
      switch (type) {
        case "APP":
          return "승인완료";
        case "REJ":
          return "승인거절";
      }
    },

    getContractStatus(type) {
      switch (type) {
        case "USED":
          return "계약중";
        case "E":
          return "계약만료";
        case "TERM":
          return "중도해지";
      }
    },

    formatPhone(num) {
      if (!num) {
        return "";
      }
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },

    goCompanyContractList() {
      this.$router.back()
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  min-width: 250px;
}
.content-edit-info {
  margin-left: 32px;
  color: #ff755a;
  font-weight: 500;
  font-size: 14px;
}
.info-content-status-app {
  color: #2CCDC3;
}
.info-content-status-rej {
  color: #f1431d;
}
.info-content-date {
  margin-left: 8px;
  font-size: 12px;
  color: #515466;
}
.contract-status-used {
  color: #2CCDC3;
}
.contract-status-e {
  color: #f1431d;
}
.contract-status-term {
  color: #12131a;
}
.info-content-date {
  margin-left: 8px;
  font-size: 12px;
  color: #515466;
}
</style>
